input[type="file"]{
    display: none; 
}

.flex-wrap{
    display:flex;
    flex-wrap:wrap;
}

.center{
    justify-content: center;
}

.paddingLeft{
    padding-left: 0.5rem;
}

.card-contact {
    background: #ffffff;
    padding: 1.25rem;
    border: 0 none;
    margin-bottom: 2rem;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    border-radius: 4px;
    border-left: 4px solid;
    border-left-color: #20409a;
    display: flex;
    justify-content: space-between;
}

.person-name{
    font-size: 1rem;
    font-weight: 700;
    color: #495057;
}

.person-phone{
    color: #6c757d;
}
