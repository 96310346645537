.agendamento {


     .agenda{
       display: flex;
       flex-direction: column;
     }

     .ageOverlayPanel {
          width: 500px;
          height: 500px;
   
          align-self: center;
          align-items: center;
      
        
          .gridDetalhes{

             h2{
               font-size: 14px;
               font-weight: bold;
               margin-top: 10px;
               border-bottom: 1px solid #d5d5d5;
             }

             a{
              font-size: 10px;
             }
          }



          .gridBotoes{
            display:  flex;
            flex-direction: column;
            margin-top: 50px;

            button {
                margin: 1rem;
                background-color: #597CA9;

            }
             

          }




     }


     



  

  
    
}