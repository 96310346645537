.consulta {
  
      .summarypaciente {
        position: relative;
        align-items: center;

        
        .title {
            font-size: 20px;
        }

        video {
            height: 90%;
            width: 100%;
        }
        
        .detail {
            color: $textSecondaryColor;
            display: block;
            margin-top: 10px;
        }
  
    }

      .summarymedico {
          position: relative;
          align-items: center;

          
          .title {
              font-size: 20px;
          }

          video {
              height: 90%;
              width: 0%;
          }
          
          .detail {
              color: $textSecondaryColor;
              display: block;
              margin-top: 10px;
          }
          
     
      }

  

     .div_flutuante{

      margin: 0;
      display:block;
      position:fixed;
      width:200px;
      height:150px;
      bottom: 5px;
      right:5px;
      color:#000000;
      z-index:100;
     
      text-align:center;
      font-weight:bold;

      video {
        height: 100%;
        width: 100%;
      }


     }

     .botoes_flutuante{

      margin: 0;
      display:block;
      position:fixed;

      width: 40%;
      height: 70px;
      bottom: 50px;
      right: 30%;
      left: 30%;
      
      color:#000000;
      z-index:100;
    
   
     
      text-align:center;
      font-weight:bold;

      button {
         background-color: #597CA9;
         padding: 5px;
         width: 100px;
         margin-left: 15px;
         margin-right: 15px;
         text-align:center;
         font-weight:bold;

      }


     }


  

  
    
}