.content {
    display: flex;
    width: 100%;
    height: 84vh;
}

.card-- {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background-color: #ffffff;
    padding: 0.2rem;
    border-radius: 4px;
}

.actions-- {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    height: 2rem;
}

input[type="date"] {
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    background-color: #f8f9fa;
    border: 1px solid #dadce0;
    color: #202124;
    padding: 0.5rem;
    border-radius: 4px;
}

.btn-search {
    outline: none;
    border: none;
    background-color: #f8f9fa;
    border: 1px solid #f8f9fa;
    border-radius: 4px;
    color: #3c4043;
    font-family: arial, sans-serif;
    font-size: 14px;
    margin: 11px 4px;
    padding: 0 16px;
    line-height: 27px;
    height: 36px;
    min-width: 54px;
    text-align: center;
    cursor: pointer;
    user-select: none;
}

.btn-search:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    background-color: #f8f9fa;
    border: 1px solid #dadce0;
    color: #202124;
}

.box--dataTable {
    display: flex;
    width: 100%;
    overflow-y: scroll;
    flex-direction: column;
}