.login {
  
   background-color: #20409a;
   height: 100%;
   display:  flex;
   align-items:  center; 
   justify-content:  center; 

  .cardmaster {
    display:  flex;
    flex-direction:  row;
    background-color: #ffffff;
    width: 450px;
    height: 360px;
    padding: 10px;
    border-radius: 10px;
    padding-bottom: 10px;
    box-shadow: 0 0 250px #000;
  }

  .cardlogin {
      display:  flex;
      flex-direction:  column;
      flex: 2;
 
   
      h1 {
        margin: 1em 0 .5em 0;
        padding: .1em;
        font-size: 12px;
        font-weight: bold;
        color: red;

       
    }
     
  }

  .layout-logo{
    display:  flex; 
    align-items:  center; 
    justify-content:  center; 
    flex: 1;

    
  }
    

  input {
    width: 100%;
    margin-top: 10px;
  }

  

  .buttonlogin{
      display:  flex;
      justify-content:  center; 
    
    
  }

  

  
    
}